import {atom} from "recoil";



export type PageSectionOptions = {
    id: string;
    title: string;
    variant: "left" | "right" | "noimage";
}

export const Fonts = {
    Poppins: `'Poppins', sans-serif`,
    Nunito: `'nunito', sans-serif`,
    IndieFlower: `'Indie Flower', 'nunito', sans-serif`,
    Inter: `'Inter', sans-serif`
};


type PaletteColor = {
    name: string;
    hex: string;
    rgb: [number, number, number],
    [k: string]: any;
}
export const Palette = {
    Default: {
        color: 'white',
        background: '#0e0e0e'
    },
    Yellow: {
        "name": "unprn Yellow",
        "hex": "ffc107",
        "rgb": [255, 193, 7] as PaletteColor['rgb'],
        "hash": "#ffc107"
    },
    MaximumYellowRed: {
        "name": "Maximum Yellow Red",
        "hex": "ffc759",
        "rgb": [255, 199, 89] as PaletteColor['rgb'],
        "cmyk": [0, 22, 65, 0],
        "hsb": [40, 65, 100],
        "hsl": [40, 100, 67],
        "lab": [83, 9, 61]
    },
    TickleMePink: {
        "name": "Tickle Me Pink",
        "hex": "ff7b9c",
        "rgb": [255, 123, 156] as PaletteColor['rgb'],
        "cmyk": [0, 52, 39, 0],
        "hsb": [345, 52, 100],
        "hsl": [345, 100, 74],
        "lab": [68, 53, 6]
    },
    BlueYonder: {
        "name": "Blue Yonder",
        "hex": "607196",
        "rgb": [96, 113, 150] as PaletteColor['rgb'],
        "cmyk": [36, 25, 0, 41],
        "hsb": [221, 36, 59],
        "hsl": [221, 22, 48],
        "lab": [48, 3, -22]
    },
    LightPeriwinkle: {
        "name": "Light Periwinkle",
        "hex": "babfd1",
        "rgb": [186, 191, 209] as PaletteColor['rgb'],
        "cmyk": [11, 9, 0, 18],
        "hsb": [227, 11, 82],
        "hsl": [227, 20, 77],
        "lab": [77, 2, -10]
    },
    Platinum: {
        "name": "Platinum",
        "hex": "e8e9ed",
        "rgb": [232, 233, 237] as PaletteColor['rgb'],
        "cmyk": [2, 2, 0, 7],
        "hsb": [228, 2, 93],
        "hsl": [228, 12, 92],
        "lab": [92, 0, -2]
    },

    withOpacity(color: Pick<PaletteColor, 'rgb'>, opacity: number) {
        return `rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]}, ${opacity})`;
    }
};
export const Colors = {
    White: "white",
    Black: "#0e0e0e",
    GrayLighten: "#C6C6C6",
    Yellow: Palette.Yellow.hash,
    Red: "#ff1233",
    YellowLighten: "rgba(255, 255, 255, 0.41)",
    LightGray: "#CBD2E0"
};

export const Media = {
    mobile: 'max-width: 767px',
    tablet: 'min-width: 768px',
    laptop: 'min-width: 1024px',
    desktop: 'min-width: 1440px'
};
export const AppBarHeight = {
    desktop: 3,
    mobile: 5
};

export const CenterSectionCSS = `
  width: 100vw;
  padding: 0 5vw;
  @media (${Media.laptop}) {
    padding: 0;
    width: 100% ;
  }      
  @media (${Media.desktop}) {
    max-width: 100rem;
  }
`;
export const PageState = atom<any>({
    key: 'pageState',
    default: undefined
});
