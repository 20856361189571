import React from 'react';
import styled from 'styled-components';
import {Colors, Fonts, Media} from "../constants";

export const Style = styled.div`

  background: black;    
  color: white;
    
  h1 {
    font-size: 3em;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: center;
    line-height: 1.1em;    
  }
  
  h2 {
    font-size: 2.5em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  a.decorated {
    border-bottom: 1px dotted currentColor;
    text-decoration: none;        
    color: inherit;
    
    &:active, &:hover {
      border-bottom: 1px solid currentColor;
    } 
  }
    
  
  @media (${Media.laptop}) {
    h1 {
      font-size: 4em;
      text-align: left;
    }
 
  }


`;
