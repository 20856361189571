import "normalize.css"
import styled, {createGlobalStyle} from 'styled-components';
import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import "../css/variables.css"
import "../css/index.scss"
import {Fonts, Media} from "../constants";
import {RecoilRoot} from "recoil";
import * as React from "react";
import {ReactNode} from "react";
import {Style} from "./Style";

const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${ Fonts.Poppins };
  }
  html, body {
    margin: 0;
    padding: 0;
    line-height: 24px ;
    letter-spacing: 0.01em ;
  }
  
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  min-height: 100vh;

  @media (${Media.laptop}) { min-height: 100vh }
`;

export type RootProps = {
    children: ReactNode;
    className?: string;
}
export function Root({children, className}: RootProps) {
    return (
        <RecoilRoot>
            <GlobalStyle/>
            <Style>
                <Container className={className}>
                    {children}
                </Container>
            </Style>
        </RecoilRoot>
    )
}
